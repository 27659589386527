import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["themeRadio", "showMoreButton", "showLessButton", "overflowSelect"]
  static values = {
    open: Boolean,
    themeId: Number,
    overflowIds: Array
  }

  declare readonly themeRadioTargets: Array<HTMLInputElement>
  declare readonly showMoreButtonTarget: HTMLButtonElement
  declare readonly showLessButtonTarget: HTMLButtonElement
  declare readonly overflowSelectTarget: HTMLSelectElement
  declare openValue: boolean
  declare themeIdValue: number
  declare overflowIdsValue: Array<number>

  themeIdValueChanged() {
    if (this.overflowIdsValue.includes(this.themeIdValue)) {
      this.overflowSelectTarget.classList.add("theme-selection-dropdown--active")
      this.overflowSelectTarget.value = this.themeIdValue.toString()
      for (const target of this.themeRadioTargets) {
        target.checked = false
      }
    } else {
      this.overflowSelectTarget.classList.remove("theme-selection-dropdown--active")
      this.overflowSelectTarget.value = ""
    }
  }

  openValueChanged() {
    if (this.openValue) {
      this.showMoreButtonTarget.classList.add("d-none")
      this.showLessButtonTarget.classList.remove("d-none")
      this.overflowSelectTarget.classList.remove("d-none")
    } else {
      this.showMoreButtonTarget.classList.remove("d-none")
      this.showLessButtonTarget.classList.add("d-none")
      this.overflowSelectTarget.classList.add("d-none")
    }
  }

  showMore() {
    this.openValue = true
  }

  showLess () {
    this.openValue = false
  }

  setThemeId(event: Event) {
    const target = event.currentTarget as HTMLInputElement
    this.themeIdValue = parseInt(target.value)
  }
}
