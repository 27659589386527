import { Controller } from "@hotwired/stimulus";
import lottie, { AnimationConfigWithPath, RendererType, AnimationItem } from "lottie-web";

export default class extends Controller {
  private animationInstance: AnimationItem | null = null;

  connect() {
    if (!localStorage.getItem('pageLoaded')) {
      const animationContainer = this.element;
      const animData: AnimationConfigWithPath<RendererType> = {
        container: animationContainer,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/confetti.json"
      };

      this.animationInstance = lottie.loadAnimation(animData);
      localStorage.setItem('pageLoaded', 'true');
      setTimeout(() => {
        if (this.animationInstance) {
          this.animationInstance.destroy();
        }
      }, 6000);
    }
  }
}
