import { Controller } from "@hotwired/stimulus"
import { blobToFile, loadingOverlay } from "../lib/utils"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  [x: string]: any;
  static values = { directUploadUrl: String };

 fileChanged(event) {
    const file = event.currentTarget.files[0];
    if (!file) {
      return;
    }

    const fileExtension = file.name.replace(/^.*\./, "");
    if (!["jpg", "jpeg", "png", "svg"].includes(fileExtension.toLowerCase())) {
      alert("Not a valid extension. Supported extensions are (.png .svg .jpg .jpeg)");
      return;
    }

    loadingOverlay.show();

    new DirectUpload(blobToFile(file), this.directUploadUrlValue).create((error, blob) => {
      loadingOverlay.hide();
      if (error) {
        alert(error);
      } else {
        this.element.dispatchEvent(new CustomEvent("saveUpload", {
          bubbles: true,
          detail: {
            imageUrl: URL.createObjectURL(file),
            imageId: blob.signed_id
          }
        }));
      }
    });

    event.currentTarget.value = null;
  }
}
