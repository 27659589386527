import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    const childElements = this.element.children;
    for (let i = 0; i < childElements.length; i++) {
      if (i > 4 && i < childElements.length - 3) {
        const columnDiv = childElements[i].querySelector('.column') || childElements[i].querySelector('.book-page');
        if (columnDiv) {
          const newDiv = document.createElement('div');
          if (i % 2 === 0) {
            newDiv.className = 'd-flex';
            newDiv.style.left = '10px';
          } else {
            newDiv.className = 'd-flex justify-content-end';
            newDiv.style.right = '10px';
          }
          if (columnDiv.classList.contains('empty')) {
            newDiv.classList.add('position-absolute');
            newDiv.style.bottom = '-2px';
          }
          const h3 = document.createElement('h3');
          h3.style.fontSize = '0.6rem';
          h3.style.color = "black"
          h3.innerText = (i - 2).toString();
          newDiv.appendChild(h3);
          columnDiv.appendChild(newDiv);
        }
      }
    }
  }

}
