import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["photoEl"];
  static values = { bookId: String, inviteUuid: String, assetId: String, index: String, description: String };

  fetchPartials = async (bookId, imageId, orientation) => {
    const form = document.getElementById("quiz-form")
    const addHiddenField = (fieldName, value) => {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = fieldName;
      hiddenField.value = value;
      form.appendChild(hiddenField);
    };

    addHiddenField("invite[pictures_attributes][][asset]", imageId);
    addHiddenField("invite[pictures_attributes][][orientation]", orientation);
    if (!this.inviteUuidValue) {
     const showOwnImagesInput = document.getElementById("OwnImagesSwitch")
     if (showOwnImagesInput) {
      addHiddenField("show_own_images", showOwnImagesInput.checked);
     }
    }

    const formData = new FormData(form)
    const response = await post("/participant/photo_upload_fragment", {
      body: formData
    })

    if (response.ok) {
      const data = await response.html
      this.updatePicturePages(data)
    } else {
      alert("Error uploading file")
    }
  }

  updatePicturePages(partials) {
    const index = this.removeSlides()

    const template = document.createElement("template")
    template.innerHTML = partials

    const newSlides = Array.from(template.content.children).reverse()

    for (const slideContent of newSlides) {
      // Need to add two extra divs, due to a bug in SlickSlider
      // See https://github.com/kenwheeler/slick/issues/3324
      const slideDiv = document.createElement("div")
      slideDiv.appendChild(document.createElement("div"))
      slideDiv.firstElementChild.appendChild(slideContent)

      $(".book-slider").slick("slickAdd", slideDiv, index)
    }

    $(".book-slider").slick("slickGoTo", index + newSlides.length, true)
  }

  removeSlides() {
    const firstPhotoIndex = document.querySelector(".photo-slides").parentElement.parentElement.dataset.slickIndex
    const photoPageCount = document.querySelectorAll(".photo-slides").length

    for (let i = 0; i < photoPageCount; i++) {
      $(".book-slider").slick("slickRemove", firstPhotoIndex)
    }

    return parseInt(firstPhotoIndex) - 1
  }

  async just_show_own_images() {
    const csrfToken = document.getElementsByName("csrf-token")[0]?.content;
    const show_own_images_val = event.currentTarget.checked;

    if (!this.inviteUuidValue) {
      this.fetchPartials(this.bookIdValue, "", "")
      return
    }

    const response = await fetch(`/invites/${this.inviteUuidValue}`, {
      method: "PATCH",
      body: JSON.stringify({ invite: { show_own_images_only: show_own_images_val } }),
      headers: { "X-CSRF-Token": csrfToken, "Content-Type": "application/json" }
    });

    if (!response.ok) {
      return;
    }

    const response_2 = await post(`/participant/render_photo_pages`, {
      body: JSON.stringify({ invite_uuid: this.inviteUuidValue }),
      headers: { "X-CSRF-Token": csrfToken, "Content-Type": "application/json" }
    });

    if (response_2.ok) {
      const data = await response_2.json;
      if (data.partials) {
        this.updatePicturePages(data.partials);
      }
    }
  }

}
