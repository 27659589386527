import { Controller } from "@hotwired/stimulus"
import { loadingOverlay, openModalFromTemplate } from "../lib/utils"
import { get } from "@rails/request.js"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    url: String,
    redirectEvents: String,
    closeEvents: String,
  }

  declare urlValue: string
  declare redirectEventsValue: string
  declare closeEventsValue: string

  abortController: AbortController | null = null
  modalEl: Element | null = null

  connect() {
    this.abortController = new AbortController()
  }

  disconnect() {
    this.abortController.abort()
  }

  async open(event: Event) {
    event.preventDefault()

    if (!this.urlValue) {
      throw new Error("remoteModal urlValue is missing")
    }

    loadingOverlay.show(100)
    const response = await get(this.urlValue)
    const modalHtml = await response.text
    loadingOverlay.hide()

    this.modalEl = openModalFromTemplate(modalHtml)

    if (this.redirectEventsValue) {
      this.redirectEventsValue.split(/ +/).forEach((eventName) => {
        this.modalEl.addEventListener(
          eventName,
          (event: CustomEvent) => {
            event.stopPropagation()
            this.element.dispatchEvent(
              new CustomEvent(eventName, {
                bubbles: event.bubbles,
                cancelable: event.cancelable,
                detail: event.detail,
              }),
            )
          },
          { signal: this.abortController.signal },
        )
      })
    }

    if (this.closeEventsValue) {
      this.closeEventsValue.split(/ +/).forEach((eventName) => {
        this.modalEl.addEventListener(
          eventName,
          (event: CustomEvent) => {
            this.close()
          },
          { signal: this.abortController.signal },
        )
      })
    }
  }

  close() {
    if (!this.modalEl) {
      return
    }

    Modal.getOrCreateInstance(this.modalEl).hide()
  }
}
