import { Controller } from "@hotwired/stimulus"
import { breakpoints, onVisible } from "../lib/utils"
import $ from "jquery"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    bookSignedId: String,
    prev: { type: String, default: "Previous page" },
    next: { type: String, default: "Next page" },
    initialSlide: { type: String, default: "0" },
    explanationContainer: { type: String, default: null }
  }

  declare readonly bookSignedIdValue: string
  declare prevValue: string
  declare nextValue: string
  declare initialSlideValue: string
  declare explanationContainerValue: string | null

  connect() {
    const sliderBreakpoint = 1400

    if (window.innerWidth < sliderBreakpoint) {
      this.element.querySelectorAll(".slide-mobile-hide").forEach(el => el.remove())
    }

    let initialSlideIndex = parseInt(this.initialSlideValue)
    if (isNaN(initialSlideIndex)) {
      initialSlideIndex = Math.max(0, $(this.element).find(".slide").index($(this.initialSlideValue)))
    }

    onVisible(this.element, () => {
      $(this.element).not(".slick-initialized").slick({
        slidesToScroll: 2,
        slidesToShow: 2,
        initialSlide: initialSlideIndex,
        dots: false,
        arrows: true,
        infinite: false,
        prevArrow: `<button type="button" class="slick-arrow slick-prev first-page" title="${this.prevValue}"><span class="arrow-text">${this.prevValue}</span><i class="bi bi-chevron-left"></i></button>`,
        nextArrow: `<button type="button" class="slick-arrow slick-next" title="${this.nextValue}"><span class="arrow-text">${this.nextValue}</span><i class="bi bi-chevron-right"></i></button>`,
        speed: 700,
        responsive: [
          {
            breakpoint: sliderBreakpoint,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1
            }
          }
        ]
      }).on("afterChange", (_event, slick, _currentSlide) => {
        const current_slider = $(slick.$slider).attr("id")

        if (!["book-slider-invite", "book-slider-cover", "book-slider-author"].includes(current_slider)) {
          return
        }

        if (this.explanationContainerValue) {
          this.changeExplanationPage()
        }

        const firstSlideActive = slick.$slides[0].classList.contains("slick-active")
        if (firstSlideActive) {
          slick.$prevArrow.addClass("first-page")
        } else {
          slick.$prevArrow.removeClass("first-page")
        }

        const lastSlideActive = slick.$slides[slick.$slides.length -1].classList.contains("slick-active")
        if (lastSlideActive) {
          slick.$nextArrow.addClass("last-page")
        } else {
          slick.$nextArrow.removeClass("last-page")
        }

        if (lastSlideActive && current_slider === "book-slider-cover" && window.innerWidth < breakpoints.md) {
          document.querySelector(".btn-holder").scrollIntoView()
        }
      })
    })
  }

  changeExplanationPage() {
    const slideType = (this.element.querySelector(".slick-current [data-slide-type]") as HTMLElement).dataset.slideType
    const explanationEl = document.querySelector(this.explanationContainerValue) as HTMLElement
    explanationEl.dataset.active = slideType
  }

  disconnect() {
    $(this.element).filter(".slick-initialized").slick("unslick")
  }

  findSlideIndex(element: HTMLElement) {
    const slideRoot = element.closest("[data-slick-index]") as HTMLElement | null
    if (!slideRoot) {
      throw new Error("Given element is not a child of a slide")
    }

    return parseInt(slideRoot.dataset.slickIndex)
  }

  replaceSlides(startIndex: number, length: number, replacementSlides: Element[]) {
    const currentSlideIndex = $(this.element).slick("slickCurrentSlide") as number

    for (let i = 0; i < length; i++) {
      $(this.element).slick("slickRemove", startIndex)
    }

    // TODO: Replace reverse() with toReversed() once it's widely supported
    for (const slideContent of replacementSlides.reverse()) {
      // Need to add two extra divs, due to a bug in SlickSlider
      // See https://github.com/kenwheeler/slick/issues/3324
      const slideDiv = document.createElement("div")
      slideDiv.appendChild(document.createElement("div"))
      slideDiv.firstElementChild.appendChild(slideContent)

      $(this.element).slick("slickAdd", slideDiv, startIndex - 1)
    }

    $(this.element).slick("slickGoTo", currentSlideIndex)
  }

  async reloadPhotoUploadPages(event) {
    const response = await get("/participant/photo_upload_fragment_new", {
      query: { signed_book_id: this.bookSignedIdValue, signed_invite_id: event.params.inviteId }
    })
    if (!response.ok) {
      return
    }

    const firstPhotoIndex = this.findSlideIndex(this.element.querySelector(".photo-slides"))
    const photoPageCount = this.element.querySelectorAll(".photo-slides").length

    const template = document.createElement("template")
    template.innerHTML = await response.html
    const newSlides = Array.from(template.content.children)

    this.replaceSlides(firstPhotoIndex, photoPageCount, newSlides)
  }

  async reloadEditFullSizeContentPages(event) {
    const response = await get(`/fragments/books/${this.bookSignedIdValue}/edit_full_size_content`)
    if (!response.ok) {
      return
    }

    const editFullSizeContentIndex = this.findSlideIndex(this.element.querySelector("[data-slide-type=\"full-size-content\"]"))

    const template = document.createElement("template")
    template.innerHTML = await response.html
    const newSlides = Array.from(template.content.children)

    this.replaceSlides(editFullSizeContentIndex, 1, newSlides)
  }
}
