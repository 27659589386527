import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { blobToFile, directUpload, loadingOverlay } from "../lib/utils"

type PictureOrientation = "portrait" | "landscape" | "square"

export default class extends Controller {
  static values = { bookId: String, inviteUuid: String, directUploadUrl: String}
  static targets = ["saveButton", "fileCountMessage"]

  declare inviteUuidValue: string
  declare bookIdValue: string
  declare readonly saveButtonTarget: HTMLElement
  declare readonly fileCountMessageTarget: HTMLElement

  selectedFiles: FileList | null = null

  filesChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement
    this.selectedFiles = inputElement.files

    this.fileCountMessageTarget.textContent = `${this.selectedFiles.length} ${this.selectedFiles.length === 1 ? 'file' : 'files'} selected`
    this.saveButtonTarget.classList.remove("disabled")
  }

  saveUpload() {
    if (!this.selectedFiles || this.selectedFiles.length === 0) {
      return
    }

    loadingOverlay.show()

    const promises = Promise.all(Array.from(this.selectedFiles).map(async file => {
      let processedFile: File, orientation: PictureOrientation

      if (file.type.startsWith("image/")) {
        ({ file: processedFile, orientation } = await this.cropImage(file))
      } else {
        processedFile = file
        orientation = "square"
      }

      const uploadResult = await directUpload(processedFile)

      const response = await post("/participant/update_asset", {
        body: {
          uuid: this.inviteUuidValue,
          book_id: this.bookIdValue,
          asset: uploadResult.signed_id,
          orientation: orientation
        },
        responseKind: "json"
      })

      if (!response.ok) {
        alert("Error uploading file")
      }
    }))

    promises.finally(() => {
      this.dispatch("uploadsComplete")
      loadingOverlay.hide()
    })
  }

  cropImage(file) {
    return new Promise<{file: File, orientation: PictureOrientation}>((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        const originalAspectRatio = img.width / img.height

        const portraitRatio = 0.625
        const portraitThreshold = 0.7
        const landscapeRatio = 1.6
        const landscapeThreshold = 1.5

        let orientation: PictureOrientation, targetAspectRatio: number, targetWidth: number, targetHeight: number

        if (originalAspectRatio < portraitThreshold) {
          orientation = "portrait"
          targetAspectRatio = portraitRatio
        } else if (originalAspectRatio > landscapeThreshold) {
          orientation = "landscape"
          targetAspectRatio = landscapeRatio
        } else {
          orientation = "square"
          targetAspectRatio = 1
        }

        if (originalAspectRatio > targetAspectRatio) {
          targetWidth = img.height * targetAspectRatio
          targetHeight = img.height
        } else {
          targetWidth = img.width
          targetHeight = img.width / targetAspectRatio
        }

        const canvas = document.createElement("canvas")
        canvas.width = targetWidth
        canvas.height = targetHeight

        const offsetX = Math.floor((img.width - targetWidth) / 2)
        const offsetY = Math.floor((img.height - targetHeight) / 2)

        const ctx = canvas.getContext("2d")
        ctx.drawImage(img, offsetX, offsetY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight)

        canvas.toBlob(async (croppedBlob) => {
          resolve({ file: blobToFile(croppedBlob), orientation })
        })
      }
    })
  }

  reloadPage() {
    window.location.href = window.location.href
  }
}
