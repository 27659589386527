import { Controller } from "@hotwired/stimulus"
import { post, get, FetchResponse } from "@rails/request.js"

export default class extends Controller<HTMLFormElement> {
  static values = {
    successEventName: String,
  }

  declare successEventNameValue: string

  abortController: AbortController | null = null

  connect() {
    if (this.element.tagName !== "FORM") {
      throw new Error("form-validation controller can only be used with form elements")
    }

    this.abortController = new AbortController()
    this.element.addEventListener("submit", this.submit.bind(this), { signal: this.abortController.signal })
  }

  disconnect() {
    this.abortController.abort()
  }

  async submit(event: SubmitEvent) {
    event.preventDefault()

    const formData = new FormData(this.element)
    const submitter = event.submitter as HTMLButtonElement | HTMLInputElement
    if (submitter.name) {
      formData.set(submitter.name, submitter.value)
    }
    const url = this.element.action
    const method = this.element.method

    let response: FetchResponse
    if (method === "GET") {
      response = await get(url, { query: formData })
    } else {
      response = await post(url, { body: formData })
    }

    if (!response.ok) {
      throw new Error(`Request to ${url} failed with status ${response.statusCode}`)
    }

    if (!this.successEventNameValue) {
      return
    }

    const detail = {
      ...Object.fromEntries(formData),
      response: response
    }

    this.element.dispatchEvent(
      new CustomEvent(this.successEventNameValue, {
        bubbles: true,
        detail
      }),
    )
  }
}
