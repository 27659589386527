import $ from "jquery"

let unsaved = false;

export function install() {
  $("input[name!=\"color\"], textarea, select").change(function() {
    unsaved = true;
  });
  $(".btn").click(function() {
    unsaved = false;
  });
  $("form").submit(function() {
    unsaved = false;
  });
}

export function resetUnsavedData() {
  unsaved = false;
}

window.addEventListener("beforeunload", function (e) {
  if (unsaved) {
    e.preventDefault();
    e.returnValue = '';
  }
});
