import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller<HTMLFormElement> {
  connect() {
    if (this.element.tagName !== "FORM") {
      throw new Error("form-validation controller can only be used with form elements")
    }

    this.element.setAttribute("novalidate", "")
  }

  openModal(event) {
    event.preventDefault()

    if (this._checkValidity()) {
      Modal.getOrCreateInstance(document.getElementById(event.params.modal)).show()
    }
  }

  submit(event) {
    if (!this._checkValidity()) {
      event.preventDefault()
    }
  }

  appendFormData(event) {
    if (!event.params.append) {
      return
    }

    const source = new FormData(document.getElementById(event.params.append) as HTMLFormElement)

    for (const pair of source) {
      event.formData.append(pair[0], pair[1])
    }
  }

  _checkValidity() {
    this.element.classList.add("was-validated")
    return this.element.checkValidity()
  }
}
