import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["image"];
  static values = {
    images: Array,
    imageids: Array
  };

  declare imageidsValue: Array<string>

  declare readonly imageTargets: HTMLElement[]

  connect() {
    this.imageidsValue = this.getImageIds();
  }

  getImageIds(): string[] {
    const imagesArray = document.querySelectorAll(".overview-images");
    return Array.from(imagesArray, (img) => img.id);
  }

  previous(): void {
    const currentImg = this.imageTargets[0].querySelector("img") as HTMLImageElement;
    const currentIndex = this.imageidsValue.indexOf(currentImg.id);
    const nextIndex = (currentIndex - 1 + this.imageidsValue.length) % this.imageidsValue.length;
    const nextImgId = this.imageidsValue[nextIndex];
    this.updateImage(currentImg, nextImgId);
  }

  next(): void {
    const currentImg = this.imageTargets[0].querySelector("img") as HTMLImageElement;
    const currentIndex = this.imageidsValue.indexOf(currentImg.id);
    const nextIndex = (currentIndex + 1) % this.imageidsValue.length;
    const nextImgId = this.imageidsValue[nextIndex];
    this.updateImage(currentImg, nextImgId);
  }

  updateImage(currentImg: HTMLImageElement, next_image_id: string): void {
    const nextImg = document.querySelector(`img[id='${next_image_id}']`)?.closest("li")?.querySelector("img") as HTMLImageElement;
    this.updateImageinOverview(currentImg.id, nextImg);
  }

  updateImageinOverview(current_image_id: string, nextImg: HTMLImageElement): void {
    const currentImg = document.querySelector(`img[id='${current_image_id}']`)?.closest("li")?.querySelector("img") as HTMLImageElement;
    this.swapIdandSrc(currentImg, nextImg);
  }

  swapIdandSrc(currentImg: HTMLImageElement, nextImg: HTMLImageElement): void {
    const currentImgLi = currentImg.closest("li")
    const nextImgLi = nextImg.closest("li")

    const currentSrc = currentImg.src;
    const currentId = currentImg.id;
    const currentDescription = currentImg.parentElement.dataset.multiPhotosDescriptionParam
    
    const nextSrc = nextImg.src;
    const nextId = nextImg.id;
    const nextDescription = nextImg.parentElement.dataset.multiPhotosDescriptionParam

    const currentAssetParam = currentImgLi.dataset.multiPhotosAssetIdParam
    const nextAssetParam = nextImgLi.dataset.multiPhotosAssetIdParam

    currentImg.src = nextSrc;
    currentImg.id = nextId;

    nextImg.src = currentSrc;
    nextImg.id = currentId;

    currentImgLi.dataset.multiPhotosAssetIdParam = nextAssetParam;
    nextImgLi.dataset.multiPhotosAssetIdParam = currentAssetParam;
    currentImgLi.dataset.multiPhotosDescriptionParam = nextDescription;
    nextImgLi.dataset.multiPhotosDescriptionParam = currentDescription;
    
    this.imageidsValue = this.getImageIds();
    this.updateOrder(currentImg.id, nextImg.id);
  }

  async updateOrder(current_image_id: string, next_image_id: string): Promise<void> {
    post("/book_creation/swap_pictures_order", {
      body: { current_image_signed_id: current_image_id, next_image_signed_id: next_image_id }
    })
  }
}
