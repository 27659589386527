import { Controller } from "@hotwired/stimulus"
import { blobToFile, directUpload, loadingOverlay, mod } from "../lib/utils"
import { DirectUpload } from "@rails/activestorage"
import $ from "jquery"
import { post } from "@rails/request.js"

const ORIENTATIONS = ["square", "portrait", "landscape"]

export default class extends Controller {
  static targets = ["croppieContainer", "orientationContainer", "zoomLabel", "rotateLabel"]
  static values = {
    directUploadUrl: String,
    inviteUuid: String,
    bookId: String,
    orientation: String,
  }

  declare readonly croppieContainerTarget: HTMLElement
  declare readonly orientationContainerTarget: HTMLElement
  declare readonly zoomLabelTarget: HTMLElement
  declare readonly rotateLabelTarget: HTMLElement

  declare directUploadUrlValue: string
  declare inviteUuidValue: string
  declare bookIdValue: string
  declare orientationValue: string

  selectedOrientation = 0
  imageUrl = null

  connect() {
    if (this.orientationValue && ORIENTATIONS.includes(this.orientationValue)) {
      this.selectedOrientation = ORIENTATIONS.indexOf(this.orientationValue)
    }

    this._setOrientation(this.selectedOrientation)
    const formEl = document.getElementById("set-orientation-id")

    if (formEl) {
      formEl.addEventListener("setOrientation", (event: CustomEvent) => {
        this._setOrientation(event.detail.index)
      })
    }
  }

  fileChanged(event: InputEvent & { currentTarget: HTMLInputElement }) {
    const file = event.currentTarget.files[0]
    if (!file) {
      return
    }

    if (file.type.startsWith("video/")) {
      this.saveVideoUpload(file)
      return
    }

    if (!file.type.startsWith("image/")) {
      alert("Not valid extension. Supported Extensions are (.png .svg .jpg .jpeg)")
      return
    }

    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      canvas.width = img.width
      canvas.height = img.height
      ctx.fillStyle = "#ffffff"
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 0, 0)
      canvas.toBlob((blob) => {
        const newFile = new File([blob], file.name, { type: file.type })
        this.imageUrl = URL.createObjectURL(newFile)
        this._cropper().croppie("bind", { url: this.imageUrl })
      }, file.type)
    }
    this.element.querySelector(".crop-options").classList.add("active")
    event.currentTarget.value = null
  }

  rotate(event: InputEvent & { currentTarget: HTMLInputElement }) {
    if (Number.parseInt(event.currentTarget.value) > 0) {
      this._cropper().croppie("rotate", -90)
    } else {
      this._cropper().croppie("rotate", 90)
    }
  }

  saveUpload() {
    const size = "{150,150}"

    this._cropper()
      .croppie("result", {
        type: "blob",
        size,
        format: "jpeg",
      })
      .then((canvasData) => {
        loadingOverlay.show()
        new DirectUpload(blobToFile(canvasData), this.directUploadUrlValue).create(
          (error, blob) => {
            loadingOverlay.hide()
            if (error) {
              alert(error)
            } else {
              this.element.dispatchEvent(
                new CustomEvent("saveUpload", {
                  bubbles: true,
                  detail: {
                    imageUrl: URL.createObjectURL(canvasData),
                    imageId: blob.signed_id,
                    orientation: ORIENTATIONS[this.selectedOrientation],
                  },
                }),
              )
            }
          },
        )
      })

    this._cropper().croppie("destroy")
    this.element.querySelector(".crop-options").classList.remove("active")
  }
  saveUploadNew() {
    const size = "{150,150}"

    this._cropper()
      .croppie("result", { type: "blob", size, format: "jpeg" })
      .then(async (canvasData) => {
        loadingOverlay.show()
        try {
          const blob = await directUpload(blobToFile(canvasData))
          const uploadResult = await post("/participant/update_asset", {
            body: {
              uuid: this.inviteUuidValue,
              book_id: this.bookIdValue,
              asset: blob.signed_id,
              orientation: ORIENTATIONS[this.selectedOrientation],
            },
          })

          if (!uploadResult.ok) {
            alert("Error uploading file")
            return
          }

          this.element.dispatchEvent(
            new CustomEvent("addAsset", {
              bubbles: true,
              detail: { result: uploadResult },
            }),
          )
        } catch (error) {
          alert(error)
        } finally {
          loadingOverlay.hide()
          this._cropper().croppie("destroy")
          this.element.querySelector(".crop-options").classList.remove("active")
        }
      })
  }

  saveVideoUpload(file: File) {
    loadingOverlay.show()
    directUpload(file)
      .then(async (blob) => {
        const uploadResult = await post("/participant/update_asset", {
          body: {
            uuid: this.inviteUuidValue,
            book_id: this.bookIdValue,
            asset: blob.signed_id,
            orientation: "square",
          },
        })

        if (!uploadResult.ok) {
          alert("Error uploading file")
          return
        }

        this.element.dispatchEvent(
          new CustomEvent("addAsset", {
            bubbles: true,
            detail: { result: uploadResult },
          }),
        )
      })
      .catch((error) => {
        alert(error)
      })
      .finally(() => {
        loadingOverlay.hide()
      })
  }

  _setOrientation(index: number) {
    index = index || 0
    this.selectedOrientation = mod(index, ORIENTATIONS.length)
    this.orientationContainerTarget.classList.remove(...ORIENTATIONS)
    this.orientationContainerTarget.classList.add(ORIENTATIONS[this.selectedOrientation])
    this.resetCropper()
  }

  _cropper() {
    if ($(this.croppieContainerTarget).data("croppie")) {
      return $(this.croppieContainerTarget)
    }
    return this.resetCropper()
  }

  resetCropper() {
    if ($(this.croppieContainerTarget).data("croppie")) {
      $(this.croppieContainerTarget).croppie("destroy")
    }

    if (ORIENTATIONS[this.selectedOrientation] === "portrait") {
      return this._initializeCropper(250, 400)
    } else if (ORIENTATIONS[this.selectedOrientation] === "landscape") {
      return this._initializeCropper(400, 250)
    } else {
      return this._initializeCropper(300, 300)
    }
  }

  _initializeCropper(width: number, height: number) {
    const cropper = $(this.croppieContainerTarget)
      .croppie({
        enableExif: true,
        viewport: {
          width: width,
          height: height,
        },
        boundary: { width: width + 100, height: height + 100 },
        enableOrientation: true,
        enforceBoundary: true,
      })
      .on("update.croppie", (ev, cropData) => {
        this.zoomLabelTarget.innerText = (Math.round(cropData.zoom * 100) / 100).toString()
        this.rotateLabelTarget.innerText = (90 * ((cropData.orientation - 1) % 4)).toString()
      })

    if (this.imageUrl) {
      cropper.croppie("bind", {
        url: this.imageUrl,
      })
    }
    return cropper
  }

  prevOrientation() {
    this._setOrientation(this.selectedOrientation - 1)
  }

  nextOrientation() {
    this._setOrientation(this.selectedOrientation + 1)
  }
}
